<template>
  <div class="page">
    <Navbar title="提现申请" />
    <van-field name="radio" label="单选框">
      <template #input>
        <van-radio-group
          v-model="type"
          direction="horizontal"
          :checked-color="COLOR_M"
        >
          <van-radio name="WCC">微信钱包</van-radio>
          <van-radio name="BAK" disabled>银行卡</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <van-field
      v-model="amountTips"
      name="可提现金额"
      label="可提现金额"
      readonly
      placeholder="可提现金额"
    />
    <van-field
      v-model="amount"
      name="提现金额"
      type="number"
      label="提现金额"
      placeholder="请输入1-200元范围内的金额"
      :rules="[{ required: true, message: '请填写提现金额' }]"
    />
    <van-row>
      <van-col span="24" class="tips color-s1"
        ><van-icon name="info-o" />
        因微信限制,每日累计最多可提现200元,如有不便还请谅解!</van-col
      >
    </van-row>
    <van-button
      class="button"
      :color="COLOR_M"
      icon="plus"
      size="small"
      @click="createExtract()"
      >提交申请</van-button
    >
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import { Field, RadioGroup, Radio, Icon } from 'vant'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    Share: Share,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Icon.name]: Icon
  },
  data () {
    return {
      loadingShow: false,
      merchantCode: '',
      type: 'WCC',
      balance: 0,
      amountTips: '100元',
      amount: ''
    }
  },
  mounted () {
    var query = this.$route.query
    this.merchantCode = query.merchantCode
    this.retrieveWallet()
    this.$refs.share.default('main')
  },
  methods: {
    async retrieveWallet () {
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/retrieveWallet')
      if (res.status === '200') {
        this.balance = res.data.coinBalance
        this.amountTips = this.balance + '元'
      }
    },
    async createExtract () {
      if (this.amount === '') {
        this.$dialog.alert({
          title: '提示',
          message: '申请金额不能为空'
        })
      } else if (this.amount > this.balance) {
        this.$dialog.alert({
          title: '提示',
          message: '申请金额必须小于可提现金额'
        })
      } else if (this.amount < 1 || this.amount > 200) {
        this.$dialog.alert({
          title: '提示',
          message: '申请金额为1到200元之间'
        })
      } else {
        this.loadingShow = true
        var pd = { amount: this.amount, type: this.type }
        const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/createWalletExtract', this.$qs.stringify(pd))
        this.loadingShow = false
        if (res.status === '200') {
          this.$router.go(-1)
        } else {
          this.$dialog.alert({
            title: '提示',
            message: res.msg
          })
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.button {
  margin-top: 20px;
  width: 50%;
}
.tips {
  margin-top: 10px;
}
</style>
